<template>
  <div class="organisationAbout w-full">
    <SuccessMessage
      v-if="successMessage"
      title="Success!"
      message="You successfully edited your organisation!"
    />
    <LTabBody>
      <template #body>
        <div v-if="!showEdit" class="w-full sm:px-6 lg:px-8 mb-16">
          <h2 class="heading3">Organisation details</h2>
          <div
            class="flex text-sm text-gray-500 dark:text-gray-400 dark:border-gray-700 flex-wrap mt-2"
          >
            <div class="w-full flex flex-col pt-4 gap-6 sm:w-1/2 lg:w-1/3">
              <div class="flex flex-row border-b pb-6">
                <div class="w-32 font-semibold">Website link</div>

                <a
                  :href="organisation.link"
                  class="text-blue-600"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ organisation.name }}</a
                >
              </div>
              <div class="flex flex-row border-b pb-6">
                <div class="w-32 font-semibold">Contact</div>
                <div class="flex-col flex">
                  <div>
                    {{ organisation.contact }}
                  </div>
                  <a
                    :href="'mailto:' + organisation.email"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ organisation.email }}</a
                  >
                </div>
              </div>

              <div
                v-if="organisation.country"
                class="flex flex-row border-b pb-6"
              >
                <div class="w-32 font-semibold">Country</div>
                <div>{{ organisation.country }}</div>
              </div>

              <div
                class="flex flex-row border-b pb-6"
                v-if="
                  organisation.linkedIn ||
                  organisation.facebook ||
                  organisation.instagram ||
                  organisation.twitter
                "
              >
                <div class="w-32 font-semibold self-center">Socials</div>
                <div class="flex gap-8">
                  <a
                    v-if="organisation.linkedIn"
                    :href="organisation.linkedIn"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="linkedin.svg" alt="" class="w-10" />
                  </a>

                  <a
                    v-if="organisation.twitter"
                    :href="organisation.twitter"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="X_logoBig.jpg" alt="" class="w-10 rounded-md" />
                  </a>

                  <a
                    v-if="organisation.facebook"
                    :href="organisation.facebook"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="facebook.png" alt="" class="w-10" />
                  </a>

                  <a
                    v-if="organisation.instagram"
                    :href="organisation.instagram"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="instagram.png" alt="" class="w-10" />
                  </a>
                </div>
              </div>
              <div class="flex flex-row border-b pb-6" v-else>
                <div class="w-32 font-semibold">Socials</div>
                <div class="">No Social media</div>
              </div>
            </div>

            <!--
              <div
                v-if="organisation.twitter"
                class="flex flex-row border-b pb-6"
              >
                <div class="w-32 font-semibold">Twitter</div>
                <div class="flex items-center">
                  <img src="twitter.png" alt="" class="w-10 mr-2" />
                  <a
                    :href="organisation.link"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ organisation.name }} Twitter</a
                  >
                </div>
              </div>
-->

            <!--              <div
                v-if="organisation.facebook"
                class="flex flex-row border-b pb-6"
              >
                <div class="w-32 font-semibold">Facebook</div>
                <div class="flex items-center">
                  <img src="facebook.png" alt="" class="w-10 mr-2" />
                  <a
                    :href="organisation.link"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ organisation.name }} Facebook</a
                  >
                </div>
              </div>-->

            <!--              <div
                v-if="organisation.instagram"
                class="flex flex-row border-b pb-6"
              >
                <div class="w-32 font-semibold">Instagram</div>
                <div class="flex items-center">
                  <img src="instagram.png" alt="" class="w-10 mr-2" />
                  <a
                    :href="organisation.link"
                    class="text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ organisation.name }}</a
                  >
                </div>
              </div>-->
          </div>
        </div>

        <div v-else>
          <LSection>
            <template #body>
              <div class="flex justify-center">
                <form @submit.prevent="onSubmit" ref="form" class="form">
                  <h1 class="heading1">
                    Registration
                    <hr class="gradientLine" />
                  </h1>
                  <div class="grid grid-cols-2 justify-center">
                    <div class="flex flex-col">
                      <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                        <label for="name">Organisation Name*</label>
                        <TextInput
                          :tabIndex="1"
                          :value="organisation.name"
                          id="name"
                          name="name"
                          placeholder="Name"
                          @change="(value) => (organisation.name = value)"
                        ></TextInput>
                      </div>
                      <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                        <label for="description">Description*</label>
                        <TextArea
                          type="text"
                          :tabIndex="2"
                          :value="organisation.description"
                          id="description"
                          name="description"
                          placeholder="Organisation Description"
                          @change="
                            (value) => (organisation.description = value)
                          "
                        ></TextArea>
                      </div>
                      <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                        <label for="contact">Contact Person*</label>
                        <TextInput
                          :tabIndex="3"
                          :value="organisation.contact"
                          id="contact"
                          name="contact"
                          placeholder="Contact Person Full Name"
                          @change="(value) => (organisation.contact = value)"
                        ></TextInput>
                      </div>
                    </div>
                    <div class="flex flex-col items-center p-4 gap-6">
                      <img
                        class="w-44 h-44 object-cover rounded-full"
                        :src="
                          organisation.imageUrl ||
                          'https://via.placeholder.com/150'
                        "
                      />
                      <ImageUrlInput
                        v-model="organisation.imageUrl"
                        :tabIndex="4"
                        @input="onImageUrlChange"
                        @change="onImageUrlChange"
                      ></ImageUrlInput>
                    </div>
                  </div>

                  <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="email">Contact Email*</label>
                      <TextInput
                        :tabIndex="4"
                        :value="organisation.email"
                        id="email"
                        name="email"
                        placeholder="Contact Email"
                        @change="(value) => (organisation.email = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="link">Link*</label>
                      <TextInput
                        :tabIndex="5"
                        :value="organisation.link"
                        id="link"
                        name="link"
                        placeholder="Link"
                        @change="(value) => (organisation.link = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="country">Country*</label>
                      <TextInput
                        :tabIndex="6"
                        :value="organisation.country"
                        id="country"
                        name="country"
                        placeholder="Country"
                        @change="(value) => (organisation.link = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="category">Category*</label>
                      <Dropdown
                        :title="organisation.category || 'Select Category'"
                        :items="[
                          'Public institution',
                          'School',
                          'University',
                          'Company',
                          'Other',
                        ]"
                        :tabIndex="7"
                        id="country"
                        name="country"
                        placeholder="Country"
                        @select="onCategoryChange"
                      ></Dropdown>
                    </div>
                    <div v-if="showOther" class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="otherDescription"
                        >Please descibe your organisation category*</label
                      >
                      <TextInput
                        :tabIndex="8"
                        id="otherDescription"
                        name="otherDescription"
                        placeholder="Other Description"
                        @change="
                          (value) => (organisation.otherDescription = value)
                        "
                      ></TextInput>
                    </div>

                    <div
                      v-if="showEmployeeCount"
                      class="w-full md:w-1/2 lg:w-1/3 mb-6"
                    >
                      <label for="employeeCount">Company size*</label>
                      <Dropdown
                        :title="organisation.employeeCount || 'Select Size'"
                        :items="[
                          '0-1 employees',
                          '2-10 employees',
                          '11-50 employees',
                          '51-200 employees',
                          '201-500 employees',
                          '501-1000 employees',
                          '1001-5000 employees',
                          '5001-10000 employees',
                          '10001+ employees',
                        ]"
                        placeholder="Employee Count"
                        @select="onEmployeeCountChange"
                      ></Dropdown>
                    </div>

                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="accordContactName"
                        >Accord Contact Name*</label
                      >
                      <TextInput
                        :tabIndex="9"
                        :value="organisation.accordContactName"
                        id="accordContactName"
                        name="accordContactName"
                        placeholder="Accord Contact Name"
                        @change="
                          (value) => (organisation.accordContactName = value)
                        "
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-12">
                      <label for="accordContactEmail"
                        >Accord Contact Email*</label
                      >
                      <TextInput
                        :tabIndex="10"
                        :value="organisation.accordContactEmail"
                        id="accordContactEmail"
                        name="accordContactEmail"
                        placeholder="Accord Contact Email"
                        @change="
                          (value) => (organisation.accordContactEmail = value)
                        "
                      ></TextInput>
                    </div>

                    <div class="heading3">Optional</div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="linkedIn">LinkedIn Account</label>
                      <TextInput
                        :tabIndex="11"
                        :value="organisation.linkedIn"
                        id="linkedIn"
                        name="linkedIn"
                        placeholder="LinkedIn Account Name"
                        @change="(value) => (organisation.linkedIn = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="twitter">Twitter Account</label>
                      <TextInput
                        :tabIndex="12"
                        :value="organisation.twitter"
                        id="twitter"
                        name="twitter"
                        placeholder="Twitter Account Name"
                        @change="(value) => (organisation.twitter = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="facebook">Facebook Account</label>
                      <TextInput
                        :tabIndex="13"
                        :value="organisation.facebook"
                        id="facebook"
                        name="facebook"
                        placeholder="facebook Account Name"
                        @change="(value) => (organisation.facebook = value)"
                      ></TextInput>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
                      <label for="instagram">Instagram Account</label>
                      <TextInput
                        :tabIndex="14"
                        :value="organisation.instagram"
                        id="instagram"
                        name="instagram"
                        placeholder="instagram Account Name"
                        @change="(value) => (organisation.instagram = value)"
                      ></TextInput>
                    </div>
                  </div>
                  <div class="items-center justify-end gap-4 flex flex-row">
                    <Button
                      class="button secondary"
                      @click="editOrganisation"
                      type="submit"
                    >
                      <template #right>Back</template>
                    </Button>
                    <Button
                      class="button primary"
                      @click="onSubmit"
                      type="submit"
                    >
                      <template #right>Save</template>
                    </Button>
                  </div>
                </form>
              </div>
            </template>
          </LSection>
        </div>
      </template>
      <template #buttons>
        <button
          v-if="user?.__isAppAdmin && showEditButton"
          class="button primary"
          @click="editOrganisation()"
        >
          Edit organisation
        </button>
      </template>
    </LTabBody>
  </div>
</template>

<script>
import LTabBody from "@/components/layout/LTabBody";
import { ref, watch } from "vue";
import { DELETE_ASSET } from "@/store/operations";
import { useStore } from "vuex";
import TextInput from "@/components/forms/TextInput";
import TextArea from "@/components/forms/TextArea.vue";
import SuccessMessage from "@/components/forms/SuccessMessage.vue";
import { useDocument } from "@/composables/useDocument";
import LSection from "@/components/layout/LSection";
import ImageUrlInput from "@/components/forms/ImageUrlInput";
import Dropdown from "@/components/forms/Dropdown";
import Button from "@/components/forms/Button.vue";
import { useUserPermissions } from "@/composables/useUserPermissions";

export default {
  name: "OrganisationAbout",
  components: {
    SuccessMessage,
    TextArea,
    LTabBody,
    TextInput,
    LSection,
    ImageUrlInput,
    Dropdown,
    Button,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    successMessage: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { user } = useUserPermissions();
    const showEditButton = ref(true);
    const showEmployeeCount = ref(false);
    const showOther = ref(false);
    const showEdit = ref(false);
    const dataType = "Organisation";
    const store = useStore();
    const showCreateAccordForm = ref(false);
    // const router = useRouter();
    const { organisation, updateOrganisation } = useDocument(
      dataType,
      props.organisationId
    );
    const pristine = ref(null);
    watch(
      () => organisation,
      (value) => {
        if (value) pristine.value = { ...value };
      },
      { immediate: true }
    );

    const onSubmit = () => {
      // todo: validate form data
      updateOrganisation(organisation.value).then(() => {
        showEdit.value = false;
        showEditButton.value = true;
      });
    };

    const onDelete = () => store.dispatch(DELETE_ASSET, organisation.value);
    const onCategoryChange = (value) => {
      organisation.value.category = value;
      showOther.value = value === "Other";
      showEmployeeCount.value = value === "Company";
    };
    const editOrganisation = () => {
      if (showEdit.value === false) {
        showEdit.value = true;
        showEditButton.value = false;
      } else {
        showEdit.value = false;
        showEditButton.value = true;
      }
    };
    return {
      editOrganisation,
      onCategoryChange,
      onDelete,
      onEmployeeCountChange: (value) =>
        (organisation.value.employeeCount = value),
      onImageUrlChange: (value) => (organisation.value.imageUrl = value),
      onSubmit,
      organisation,
      showCreateAccordForm,
      showEdit,
      showEditButton,
      showEmployeeCount,
      showOther,
      user,
    };
  },
};
</script>
